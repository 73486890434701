/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import heroBgMobile from 'assets/images/hero/home/mobile.avif';
import heroBgTablet from 'assets/images/hero/home/tablet.avif';
import heroBgDesktop from 'assets/images/hero/home/desktop.avif';

export const Head = () => (
  <>
    <title>Geodezja Olsztyn | Profesjonalne pomiary i usługi geodezyjne</title>
    <meta
      name="description"
      content="Zapraszamy do zapoznania się z naszą ofertą na pomiary geodezyjne, inwentaryzacje powykonawcze, obsługi inwestycji oraz nadzory geodezyjne. Obsługujemy klientów indywidualnych oraz przedsiębiorstwa, w tym z sektora lotniskowego."
    />
    <meta name="google-site-verification" content="RcwO_8_E1ajZlD7qsPPqKVjk8AQlbkIBrLPinkKbHw8" />
    <link rel="canonical" href="https://geo-partner.pl" />
  </>
);

const Main = styled.main`
  margin: 0;

  h2 {
    text-align: center;
  }
  
  p {
    line-height: 150%;
  }

  .subtitle {
    margin: 0;
    text-transform: uppercase;
  }
`;

const Projects = styled.section`
  .list-projects {
    list-style: none;
    margin: 0 auto 5rem;
    max-width: 840px;
    padding: 0;

    ul {
      list-style: square;
      margin: 0 0 4rem;

      ul {
        list-style: circle;
        margin: 1rem 0 1.5rem;
      }
    }

    > li {
      padding: 15px 15px 15px 25px;
      position: relative;

      &:last-child {
        > ul {
          margin: 0;
        }
      }

      &::after {
        background: ${({ theme }) => theme.secondary};
        content: '';
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 1px;
      }

      &::before {
        background: ${({ theme }) => theme.secondary};
        border-radius: 100%;
        content: '';
        height: 13px;
        left: -6px;
        position: absolute;
        top: 0;
        width: 13px;
      }

      h3 {
        margin: -21px 0 2rem;
      }

      @media only screen and (min-width: 960px) {
        padding: 0 15px 15px 160px;
        
        &::after {
          left: 136px;
        }

        &::before {
          left: 130px;
        }

        ul {
          position: relative;
          top: -7px;
        }

        h3 {
          left: 0;
          margin: 0;
          position: absolute;
          top: -9px;
          position: absolute;
        }
      }
    }
  }
`;

const Services = styled.ul`
  display: grid;
  grid-gap: 50px 30px;
  list-style: none;
  margin: 5rem 0;
  padding: 0;

  @media only screen and (min-width: 640px) { 
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }

  h3 {
    margin-top: 3rem;
  }

  img {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
  }
`;

const Clients = styled.ul`
  align-items: center;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  list-style: none;
  margin: 5rem auto;
  max-width: 800px;
  padding: 0;

  @media only screen and (min-width: 800px) {
    grid-gap: 50px 25px;
  }

  li {
    text-align: center;
  }

  svg {
    max-width: 100%;
  }
`;

const Hero = styled.section`
  /* background-color: #ddd; */
  background-image: url(${heroBgMobile});
  background-repeat: no-repeat;
  background-position: 50% 0; 
  /* background-size: cover; */
  margin: 0;
  padding: 280px 0 0;

  h1 {
    margin: 0 0 3rem;
    text-align: left;

    &::after {
      left: 0;
      height: 12px;
      bottom: 0;
      right: auto;
    }
  }

  @media only screen and (max-width: 639px) {
    .row {
      padding: 0;
    }
  }
  
  .hero {
    &__content {
      background: ${({ theme }) => theme.white};
      border-radius: 0 10px 0 0;
      margin: -10px 0 0;
      padding: 25px 15px 5px;
      max-width: 635px;
      width: 100%;
    }

    &__subtitle,
    &__title {
      color: #24214c;
    }

    &__title {
      font-size: 3.5rem;
      font-weight: 700;
      line-height: 120%;
      margin: 1rem 0 .5rem;
      text-transform: uppercase;
    }

    &__subtitle {
      font-size: 1.8rem;
      letter-spacing: 0.02em;
      margin: 0;
    }
  }

  @media only screen and (min-width: 640px) {
    margin: 0 0 50px;

    .hero {
      &__content {
        border-radius: 10px 10px 0 0;
        /* border: 1px solid #b6b6b6;
        border-bottom: 0; */
        padding: 30px 40px 15px;
      }

      &__subtitle {
        font-size: 2.2rem;
        margin: 0 0 1rem;
      }

      &__title {
        font-size: 4.5rem;
        margin: 1.5rem 0 .5rem;
      }
    }
  }

  @media only screen and (min-width: 480px) { 
    background-size: 100% auto;
  }

  @media only screen and (min-width: 768px) { 
    background-image: url(${heroBgTablet});
  }

  @media only screen and (min-width: 1024px) { 
    background-image: url(${heroBgDesktop});
    background-position: 50% 30%;
    background-size: cover;
  }
`;

const IndexPage = ({ data }) => (
  <Main className="homepage">
    <Hero>
      <div className="row">
        <div className="hero__content">
          <h1>
            <span className="hero__title">Geo Partner</span>
            <span className="hero__subtitle">firma geodezyjna</span>
          </h1>
          <p>Profesjonalne usługi geodezyjne dla firm i&nbsp;klientów indywidualnych na terenie Olsztyna oraz w&nbsp;powiatach województwa warmińsko-mazurskiego.</p>
          <p>Specjalizujemy się w <strong>geodezyjnej obsłudze lotnisk i&nbsp;lądowisk</strong>.</p>
        </div>
      </div>
    </Hero>

    <section className="box">
      <div className="row">
        <h2>Nasza oferta</h2>

        <Services>
          <li>
            <GatsbyImage
              image={getImage(data.mapa_ewidencji)}
              alt="Geodezja Olsztyn - mapa ewidencji"
              loading="eager"
            />
            <h3>Geodezja ogólna</h3>
            <p>Przy wielu czynnościach, które dotyczą  nieruchomości i&nbsp;jej granic powstaje dokumentacja geodezyjna. Jest ona m.&nbsp;in. podstawą zmian w&nbsp;ewidencji gruntów i&nbsp;budynków czy służy do uregulowania stanu prawnego nieruchomości...</p>
          </li>

          <li>
            <GatsbyImage
              image={getImage(data.obsluga)}
              alt="geodezyjna obsługa inwestycji"
              loading="eager"
            />
            <h3>Geodezyjna obsługa inwestycji</h3>
            <p>Każda inwestycja budowlana, czy to budowa domu jednorodzinnego, budynku wielokondygnacyjnego, obiektu przemysłowego, drogi czy linii kolejowej wymaga obecności&nbsp;geodety...</p>
          </li>

          <li>
            <GatsbyImage
              image={getImage(data.nadzor)}
              alt="nadzór geodezyjny Olsztyn"
              loading="eager"
            />
            <h3>Nadzory geodezyjne</h3>
            <p>W&nbsp;trakcie  wszelkiego rodzaju  inwestycji budowlanych istnieje potrzeba pełnienia nadzoru nad jej prawidłową realizacją...</p>
          </li>

          <li>
            <GatsbyImage
              image={getImage(data.airport)}
              alt="geodezyjna obsługa lotnisk i lądowisk"
              loading="eager"
            />
            <h3>Geodezyjna obsługa lotnisk i&nbsp;lądowisk</h3>
            <p>W&nbsp;Polsce funkcjonuje obecnie około 15 głównych portów lotniczych. Oprócz tego, znajdują się także liczne mniejsze lotniska czy lądowiska dla helikopterów...</p>
          </li>
        </Services>

        <p className="text-center">
          <Link to="/oferta/" className="btn">Dowiedz się więcej</Link>
        </p>
      </div>
    </section>

    <Projects className="box">
      <div className="row">
        <h2>Nasze realizacje</h2>

        <ul className="list-projects">
          <li>
            <h3>2024-2021</h3>
            <ul>
              <li>
                kompleksowa obsługa geodezyjna:
                <ul>
                  <li><strong>Portu Lotniczego Olsztyn - Mazury</strong></li>
                  <li>budowy obwodnicy Smolajn w&nbsp;ciągu DK51</li>
                  <li>rozbudowy drogi DW527 na odcinku Łukta - Olsztyn</li>
                </ul>
              </li>
              <li>
                nadzór geodezyjny nad budową:
                <ul>
                  <li><strong>linii tramwajowej w&nbsp;Olsztynie</strong></li>
                  <li>
                    <strong>drogi S16 Olsztyn - Ełk</strong>, odcinek Borki Wielkie - Mrągowo
                  </li>
                  <li>linii kolejowej nr 221 na odcinku Gutkowo - Dobre Miasto</li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <h3>2020-2015</h3>
            <ul>
              <li>
                kompleksowa obsługa geodezyjna:
                <ul>
                  <li><strong>Portu Lotniczego Olsztyn - Mazury</strong></li>
                  <li>budowy ulicy Pstrowskiego w&nbsp;Olsztynie z&nbsp;połączeniem do obwodnicy Olsztyna</li>
                  <li>budowy ulic Leonharda i&nbsp;Towarowej w Olsztynie z połaczeniem do obwodnicy Olsztyna</li>
                  <li>rozbudowy drogi wojewódzkiej DW504 na odcinku Frombork - Braniewo</li>
                </ul>
              </li>
              <li>
                nadzór geodezyjny nad budową:
                <ul>
                  <li><strong>obwodnicy Olsztyna</strong></li>
                  <li>drogi wojewódzkiej DW512 na odcinku Pieniężno - Bartoszyce</li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <h3>2014-2012</h3>
            <ul>
              <li>
                kompleksowa obsługa geodezyjna budowy:
                <ul>
                  <li>linii kolejowej E65 - odcinek Redaki - granica LCS Iława</li>
                  <li>drogi S8 - odcinek Piotrków Trybunalski - Rawa Mazowiecka</li>
                </ul>
              </li>
              <li>
                nadzór geodezyjny nad budową:
                <ul>
                  <li>obwodnicy Olecka (7,6km)</li>
                  <li>drogi wojewódzkiej Srokowo - Gołdap (77,5km)</li>
                  <li>linii tramwajowej w Olsztynie</li>
                </ul>
              </li>
              <li>wznowienie granic w obrębie Jastrzębie Zdrój</li>
              <li>mapy do celów projektowych internetu szerokopasmowego dla Mazowsza (140km)</li>
              <li>ustalenie linii brzegowych strug, rzek i&nbsp;jezior (14km)</li>
            </ul>
          </li>
        </ul>

        <p style={{ textAlign: 'center' }}>
          <Link to="/realizacje/" className="btn">Więcej realizacji</Link>
        </p>
      </div>
    </Projects>

    <section className="box" style={{ marginBottom: '70px' }}>
      <div className="row">
        <h2>Nasi klienci i partnerzy</h2>

        <Clients>
          <li>
            <svg width="160" height="50" viewBox="0 0 160 50">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_2" data-name="Rectangle 2" width="147.914" height="36.918" />
                </clipPath>
                <clipPath id="clip-Web_1920_5">
                  <rect width="160" height="50" />
                </clipPath>
              </defs>
              <g id="budimex" data-name="Web 1920 – 1" clipPath="url(#clip-Web_1920_5)">
                <rect width="160" height="50" fill="#fff" />
                <g id="Web_1920_1-2-3" data-name="Web 1920 – 1" transform="translate(6 7)" clipPath="url(#clip-path)">
                  <rect id="budimex_Rectangle_1" data-name="Rectangle 1" width="147.914" height="36.918" fill="#fff" />
                  <g id="_f8c126ff" data-name="#f8c126ff" transform="translate(4.19 3.477)">
                    <g id="budimex_1" data-name="Group 1" transform="translate(0 0)">
                      <path id="Layer" d="M4.053.631a.926.926,0,0,1,.785.062c.178.2.143.49.16.731C5.007,4.8,5,8.191,5.007,11.57a6.914,6.914,0,0,1,3.566-1.132,11.378,11.378,0,0,1,3.95.455,6.97,6.97,0,0,1,2.47,1.382A7.8,7.8,0,0,1,17.2,15.957a15.506,15.506,0,0,1,.553,4.092c.045.455-.027.9-.009,1.355a16.265,16.265,0,0,1-.481,3.531,7.836,7.836,0,0,1-1.534,3.112,6.085,6.085,0,0,1-2.291,1.676,10.066,10.066,0,0,1-3.067.722,23.231,23.231,0,0,1-6.883-.588,24.758,24.758,0,0,1-3.21-.883V2.958a2.164,2.164,0,0,1,.125-.981,1.26,1.26,0,0,1,.785-.517C2.145,1.175,3.09.863,4.053.631ZM5,15.164c.009,3.7,0,7.4.009,11.1a12.463,12.463,0,0,0,3.629.49,5.428,5.428,0,0,0,2.1-.321,2.832,2.832,0,0,0,1.516-1.578,10.632,10.632,0,0,0,.633-3.62,18.289,18.289,0,0,0-.294-4.128,4.015,4.015,0,0,0-.936-1.935,3.664,3.664,0,0,0-2.443-.936A7.161,7.161,0,0,0,5,15.164Z" transform="translate(-0.28 -0.575)" fill="#f8c126" fillRule="evenodd" />
                      <path id="Layer-2" data-name="Layer" d="M485.935,5.085A1.212,1.212,0,0,1,486.8,4.8h2.434a1.344,1.344,0,0,1,.865.241.987.987,0,0,1,.294.74q.013,13.521.009,27.042a28.557,28.557,0,0,1-3.861,1.007,28.484,28.484,0,0,1-4.44.535,12.089,12.089,0,0,1-5.029-.856,6.066,6.066,0,0,1-2.238-1.73,7.99,7.99,0,0,1-1.364-2.808,16.292,16.292,0,0,1-.526-3.656,16.607,16.607,0,0,1,.74-6.1,7.508,7.508,0,0,1,1.774-2.871,6.617,6.617,0,0,1,2.363-1.48,10.122,10.122,0,0,1,3.486-.588,7.151,7.151,0,0,1,4.342,1.15V6.013A1.246,1.246,0,0,1,485.935,5.085ZM479.6,18.566a2.875,2.875,0,0,0-1.186,1.373,8.928,8.928,0,0,0-.579,2.978,19.993,19.993,0,0,0,.2,4.44,4.274,4.274,0,0,0,1.239,2.523,4.021,4.021,0,0,0,2.6.722,12.817,12.817,0,0,0,3.771-.49v-11.1a7.13,7.13,0,0,0-3.263-.945A5.419,5.419,0,0,0,479.6,18.566Z" transform="translate(-430.788 -4.423)" fill="#f8c126" fillRule="evenodd" />
                      <path id="Layer-3" data-name="Layer" d="M709.258,22.687a2.888,2.888,0,0,1,1.774.2,2.855,2.855,0,0,1,1.649,2.024,2.668,2.668,0,0,1-.41,2.024,2.935,2.935,0,0,1-1.89,1.239,2.9,2.9,0,0,1-2.514-.767,2.97,2.97,0,0,1-.321-3.664A2.608,2.608,0,0,1,709.258,22.687Z" transform="translate(-644.046 -20.661)" fill="#f8c126" />
                      <path id="Layer-4" data-name="Layer" d="M811.269,112.029a15.544,15.544,0,0,1,7.044-.99,9.473,9.473,0,0,1,4.636,1.435,13.7,13.7,0,0,1,3.459-1.284,12.434,12.434,0,0,1,3.45-.169,11.57,11.57,0,0,1,3.344.642,5.184,5.184,0,0,1,2.452,1.792,5.651,5.651,0,0,1,.945,2.9c.045.49.018.981.027,1.471V129.6a1.129,1.129,0,0,1-.339.874,1.428,1.428,0,0,1-.936.223c-.776-.009-1.551,0-2.318,0a1.215,1.215,0,0,1-.9-.312,1.362,1.362,0,0,1-.268-.9c.009-3.834,0-7.659.009-11.484a4.114,4.114,0,0,0-.455-1.9,2.394,2.394,0,0,0-1.159-1,4.939,4.939,0,0,0-1.649-.33,7.292,7.292,0,0,0-3.905.945,13.991,13.991,0,0,1,.116,2.113v11.715a1.244,1.244,0,0,1-.294.892,1.223,1.223,0,0,1-.838.259H821.2a1.127,1.127,0,0,1-.847-.312,1.5,1.5,0,0,1-.25-1.016v-11.2a3.718,3.718,0,0,0-.722-2.425,2.72,2.72,0,0,0-1.516-.847,6.489,6.489,0,0,0-3.611.294,10.206,10.206,0,0,0-1.23.562c-.009,4.6,0,9.192-.009,13.793a1,1,0,0,1-1.159,1.15q-1.186-.009-2.38,0a1.3,1.3,0,0,1-.892-.259,1.246,1.246,0,0,1-.285-.9V113.687A11.167,11.167,0,0,1,811.269,112.029Z" transform="translate(-736.258 -101.132)" fill="#f8c126" />
                      <path id="Layer-5" data-name="Layer" d="M1165.666,111.683a11.895,11.895,0,0,1,7.445-.018,6.3,6.3,0,0,1,2.318,1.48,6.848,6.848,0,0,1,1.6,2.577,13.932,13.932,0,0,1,.7,4.806,3.939,3.939,0,0,1-.4,1.97,1.429,1.429,0,0,1-1.23.535h-10.646a6.864,6.864,0,0,0,.3,2.042,3,3,0,0,0,1.587,1.756,7.774,7.774,0,0,0,3.37.571,12.847,12.847,0,0,0,3.861-.517,9.815,9.815,0,0,1,1.114-.3c.223-.045.375.143.5.294a5.266,5.266,0,0,1,.74,1.587,1.174,1.174,0,0,1,.036.963,2.312,2.312,0,0,1-1.034.785,12.658,12.658,0,0,1-3.236.8,18.717,18.717,0,0,1-4.467.027,11.293,11.293,0,0,1-3.406-.883,6.756,6.756,0,0,1-2.033-1.427,7.2,7.2,0,0,1-1.516-2.568,13.1,13.1,0,0,1-.615-3.21,19.736,19.736,0,0,1,.125-4.529,9.1,9.1,0,0,1,2.389-5.162A7.33,7.33,0,0,1,1165.666,111.683Zm2.9,3.236a3.163,3.163,0,0,0-2.3,1.328,6.28,6.28,0,0,0-.811,3.406c2.532-.009,5.073.009,7.614-.009a9.569,9.569,0,0,0-.151-1.953,3.474,3.474,0,0,0-1.364-2.256A4.3,4.3,0,0,0,1168.564,114.919Z" transform="translate(-1057.131 -101.222)" fill="#f8c126" fillRule="evenodd" />
                      <path id="Layer-6" data-name="Layer" d="M230.841,115.35a1.686,1.686,0,0,1,1.025-.187h2.211a1.149,1.149,0,0,1,.883.339,1.643,1.643,0,0,1,.25,1.043V126.5a6.325,6.325,0,0,0,.535,3.094,2.736,2.736,0,0,0,1.623,1.328,5.436,5.436,0,0,0,2.042.178,4.559,4.559,0,0,0,2.461-.883,4.413,4.413,0,0,0,.954-1.079.972.972,0,0,0,.053-.481V116.777a2.073,2.073,0,0,1,.214-1.23,1.056,1.056,0,0,1,.892-.383h2.265a1.6,1.6,0,0,1,1.052.223,1.234,1.234,0,0,1,.339.936c-.009,4.333,0,8.675-.009,13.017a2.259,2.259,0,0,0,.374,1.4,1.466,1.466,0,0,0,1.239.437,2.108,2.108,0,0,1,1.106.062.836.836,0,0,1,.374.642q.125.883.241,1.765a.717.717,0,0,1-.267.642,2.187,2.187,0,0,1-.936.428,8.227,8.227,0,0,1-1.872.169,4.013,4.013,0,0,1-2.693-.981,3.377,3.377,0,0,1-.936-1.641,6.439,6.439,0,0,1-2.113,1.641,9.111,9.111,0,0,1-4.164.981,11.846,11.846,0,0,1-3.067-.374,6.232,6.232,0,0,1-2.559-1.355,5.9,5.9,0,0,1-1.739-3.308,17.386,17.386,0,0,1-.16-3.335V116.268A1.072,1.072,0,0,1,230.841,115.35Z" transform="translate(-209.932 -104.943)" fill="#f8c126" />
                      <path id="Layer-7" data-name="Layer" d="M712.947,115.252a1.855,1.855,0,0,1,.544-.062h2.434a1.072,1.072,0,0,1,.8.294,1.307,1.307,0,0,1,.276.865q-.013,8.372-.009,16.744a1.741,1.741,0,0,1-.178,1.061,1.081,1.081,0,0,1-.892.383h-2.55a1.081,1.081,0,0,1-.892-.383,1.68,1.68,0,0,1-.187-1q.009-8.238.009-16.468a2.229,2.229,0,0,1,.107-.972.864.864,0,0,1,.535-.464Z" transform="translate(-648.813 -104.969)" fill="#f8c126" />
                      <path id="Layer-8" data-name="Layer" d="M1369.6,115.459c.1-.25.4-.259.624-.259,1.132.009,2.265-.009,3.4.009a1.04,1.04,0,0,1,.847.58c1.008,1.658,2,3.335,3,4.993.972-1.614,1.935-3.236,2.916-4.85a1.726,1.726,0,0,1,.553-.633,1.546,1.546,0,0,1,.606-.1h3.228c.205.009.464.045.562.259a.888.888,0,0,1-.143.874c-1.739,2.657-3.486,5.3-5.216,7.962q3.023,4.6,6.045,9.21a.8.8,0,0,1,.089.82.626.626,0,0,1-.544.223c-1.1,0-2.193-.009-3.281,0a1.208,1.208,0,0,1-.589-.125,1.533,1.533,0,0,1-.446-.508q-1.89-2.871-3.78-5.751-1.819,2.755-3.62,5.51a3.173,3.173,0,0,1-.544.7,1.088,1.088,0,0,1-.7.178c-1.079-.009-2.149,0-3.228,0a.618.618,0,0,1-.571-.259,1,1,0,0,1,.151-.918c1.97-3.031,3.959-6.054,5.929-9.085l-5.082-7.792A1.151,1.151,0,0,1,1369.6,115.459Z" transform="translate(-1246.736 -104.98)" fill="#f8c126" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </li>
          <li>
            <svg
              version="1.1"
              id="Warstwa_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="259.999px"
              height="53.453px"
              viewBox="23.442 21.678 259.999 53.453"
              enableBackground="new 23.442 21.678 259.999 53.453"
              xmlSpace="preserve"
            >
              <g>
                <path
                  fill="#00B3DD"
                  d="M101.935,38.129l-8.458-8.456c-0.285-0.285-0.669-0.443-1.069-0.443H60.84l11.48,11.48h28.544
		c0.611,0,1.162-0.369,1.398-0.933C102.496,39.212,102.366,38.561,101.935,38.129"
                />
                <path
                  fill="#BCD85F"
                  d="M55.895,40.707L37.468,22.279c-0.385-0.385-0.906-0.601-1.452-0.601h-10.52c-0.83,0-1.58,0.5-1.896,1.267
		c-0.32,0.768-0.143,1.649,0.444,2.237l13.267,13.265c1.473,1.462,3.421,2.264,5.49,2.264L55.895,40.707"
                />
                <path
                  fill="#16A1A2"
                  d="M72.32,40.71L48.616,64.413c-0.385,0.386-0.907,0.603-1.452,0.603H36.643c-0.829,0-1.579-0.5-1.896-1.269
		c-0.319-0.769-0.142-1.649,0.444-2.237l20.704-20.8H72.32"
                />
                <path
                  fill="#717576"
                  d="M73.004,58.863h2.973c0.622,0,1.145,0.082,1.556,0.246c0.412,0.166,0.78,0.4,1.114,0.707
		c0.307,0.308,0.544,0.673,0.715,1.099c0.171,0.422,0.255,0.905,0.255,1.45c0,1.213-0.336,2.128-1.016,2.746
		c-0.679,0.621-1.675,0.929-2.996,0.929h-1.042v5.165h-1.559V58.863 M74.563,64.77h0.85c0.939,0,1.606-0.205,1.997-0.609
		c0.388-0.409,0.582-0.958,0.582-1.653c0-0.849-0.225-1.456-0.672-1.821c-0.448-0.365-1.072-0.547-1.875-0.547h-0.882V64.77z"
                />
                <path
                  fill="#717576"
                  d="M80.134,66.693c0-0.705,0.075-1.346,0.229-1.916c0.153-0.571,0.38-1.06,0.688-1.461
		c0.309-0.399,0.685-0.709,1.132-0.928c0.447-0.219,0.972-0.326,1.574-0.326c0.601,0,1.125,0.107,1.574,0.326
		c0.447,0.219,0.825,0.527,1.132,0.928c0.306,0.401,0.534,0.89,0.688,1.461c0.153,0.57,0.231,1.211,0.231,1.916
		c0,0.709-0.078,1.349-0.231,1.922c-0.154,0.568-0.382,1.059-0.688,1.457c-0.307,0.4-0.685,0.711-1.132,0.93
		c-0.449,0.218-0.973,0.326-1.574,0.326c-0.602,0-1.127-0.108-1.574-0.326c-0.447-0.219-0.824-0.527-1.132-0.93
		c-0.307-0.398-0.535-0.889-0.688-1.457C80.209,68.042,80.134,67.402,80.134,66.693 M81.689,66.693c0,1.146,0.165,2.004,0.502,2.574
		c0.335,0.571,0.858,0.857,1.564,0.857c0.705,0,1.231-0.286,1.564-0.857c0.336-0.57,0.504-1.429,0.504-2.574
		c0-1.142-0.168-2-0.504-2.57c-0.333-0.572-0.859-0.857-1.564-0.857c-0.707,0-1.229,0.285-1.564,0.857
		C81.854,64.693,81.689,65.553,81.689,66.693z"
                />
                <path
                  fill="#717576"
                  d="M89.093,64.133c0-0.331-0.006-0.658-0.024-0.98c-0.018-0.326-0.033-0.646-0.045-0.967h1.433l0.054,1.416
		h0.034c0.239-0.555,0.555-0.949,0.958-1.185c0.4-0.237,0.937-0.354,1.607-0.354v1.469c-0.174-0.034-0.363-0.053-0.564-0.053
		c-0.58,0-1.048,0.244-1.415,0.733c-0.364,0.489-0.55,1.228-0.55,2.22v4.773h-1.486v-7.072"
                />
                <path
                  fill="#717576"
                  d="M98.31,71.098c-0.177,0.062-0.381,0.114-0.61,0.16c-0.228,0.047-0.444,0.07-0.646,0.07
		c-0.685,0-1.201-0.155-1.555-0.469c-0.355-0.312-0.532-0.869-0.532-1.672v-5.799h-1.433v-1.203h1.433v-1.838l1.486-0.442v2.28
		h1.856v1.203h-1.856v5.322c0,0.52,0.07,0.885,0.21,1.096c0.141,0.213,0.385,0.318,0.728,0.318c0.188,0,0.354-0.025,0.495-0.07
		c0.141-0.048,0.282-0.101,0.423-0.16L98.31,71.098"
                />
                <polyline
                  fill="#717576"
                  points="104.089,58.863 105.648,58.863 105.648,69.861 109.696,69.861 109.696,71.205 104.089,71.205
		104.089,58.863 	"
                />
                <path
                  fill="#717576"
                  d="M110.564,66.693c0-0.705,0.074-1.346,0.228-1.916c0.153-0.571,0.385-1.06,0.691-1.461
		c0.307-0.399,0.681-0.709,1.13-0.928c0.45-0.219,0.972-0.326,1.573-0.326c0.604,0,1.126,0.107,1.573,0.326
		c0.45,0.219,0.827,0.527,1.132,0.928c0.308,0.401,0.539,0.89,0.691,1.461c0.153,0.57,0.231,1.211,0.231,1.916
		c0,0.709-0.079,1.349-0.231,1.922c-0.153,0.568-0.384,1.059-0.691,1.457c-0.306,0.4-0.682,0.711-1.132,0.93
		c-0.447,0.218-0.969,0.326-1.573,0.326c-0.601,0-1.123-0.108-1.573-0.326c-0.449-0.219-0.823-0.527-1.13-0.93
		c-0.306-0.398-0.538-0.889-0.691-1.457C110.639,68.042,110.564,67.402,110.564,66.693 M112.12,66.693
		c0,1.146,0.165,2.004,0.504,2.574c0.336,0.571,0.855,0.857,1.562,0.857c0.709,0,1.231-0.286,1.568-0.857
		c0.336-0.57,0.504-1.429,0.504-2.574c0-1.142-0.168-2-0.504-2.57c-0.336-0.572-0.859-0.857-1.568-0.857
		c-0.706,0-1.226,0.285-1.562,0.857C112.285,64.693,112.12,65.553,112.12,66.693z"
                />
                <path
                  fill="#717576"
                  d="M123.508,71.098c-0.181,0.062-0.384,0.114-0.612,0.16c-0.229,0.047-0.445,0.07-0.646,0.07
		c-0.685,0-1.201-0.155-1.557-0.469c-0.354-0.312-0.531-0.869-0.531-1.672v-5.799h-1.432v-1.203h1.432v-1.838l1.487-0.442v2.28
		h1.859v1.203h-1.859v5.322c0,0.52,0.068,0.885,0.209,1.096c0.141,0.213,0.384,0.318,0.728,0.318c0.189,0,0.354-0.025,0.496-0.07
		c0.141-0.048,0.282-0.101,0.426-0.16V71.098"
                />
                <path
                  fill="#717576"
                  d="M124.761,64.133c0-0.331-0.006-0.658-0.024-0.98c-0.019-0.326-0.034-0.646-0.045-0.967h1.433l0.053,1.346
		h0.033c0.237-0.521,0.544-0.893,0.922-1.123c0.376-0.23,0.829-0.346,1.361-0.346c0.79,0,1.426,0.233,1.911,0.699
		c0.482,0.467,0.723,1.192,0.723,2.186v6.259h-1.487v-5.819c0-0.718-0.125-1.238-0.378-1.563c-0.253-0.322-0.622-0.486-1.105-0.486
		c-0.588,0-1.054,0.203-1.396,0.612c-0.343,0.404-0.515,1.015-0.515,1.828v5.43h-1.486V64.133"
                />
                <path fill="#717576" d="M133.355,58.475h1.629v1.768h-1.629V58.475z M133.426,62.186h1.486v9.021h-1.486V62.186z" />
                <path
                  fill="#717576"
                  d="M142.514,63.76c-0.259-0.142-0.526-0.246-0.796-0.316c-0.271-0.07-0.576-0.107-0.918-0.107
		c-0.733,0-1.325,0.271-1.788,0.814c-0.459,0.543-0.688,1.42-0.688,2.635c0,1.05,0.223,1.857,0.67,2.422
		c0.451,0.564,1.039,0.85,1.772,0.85c0.353,0,0.678-0.048,0.979-0.142c0.3-0.097,0.583-0.207,0.841-0.336l0.069,1.36
		c-0.223,0.097-0.514,0.184-0.868,0.267c-0.351,0.082-0.694,0.123-1.021,0.123c-0.676,0-1.261-0.116-1.763-0.353
		c-0.501-0.236-0.916-0.559-1.244-0.966c-0.333-0.405-0.579-0.888-0.744-1.44c-0.166-0.553-0.246-1.148-0.246-1.785
		c0-0.732,0.09-1.391,0.272-1.973c0.181-0.582,0.445-1.078,0.796-1.485c0.348-0.405,0.771-0.718,1.27-0.937
		c0.504-0.22,1.066-0.328,1.691-0.328c0.306,0,0.621,0.03,0.945,0.09c0.322,0.059,0.604,0.146,0.841,0.266L142.514,63.76"
                />
                <polyline
                  fill="#717576"
                  points="149.25,62.186 149.25,63.53 145.079,69.932 149.339,69.932 149.339,71.205 143.555,71.205
		143.555,69.861 147.73,63.459 143.696,63.459 143.696,62.186 149.25,62.186 	"
                />
                <path
                  fill="#717576"
                  d="M153.797,73.042c-0.227,0.767-0.507,1.308-0.85,1.619c-0.342,0.312-0.797,0.469-1.363,0.469
		c-0.178,0-0.355-0.009-0.539-0.026c-0.183-0.017-0.339-0.043-0.468-0.078l0.072-1.346c0.117,0.06,0.24,0.104,0.369,0.133
		c0.129,0.029,0.276,0.045,0.441,0.045c0.216,0,0.398-0.07,0.559-0.213c0.159-0.142,0.303-0.449,0.433-0.918l0.354-1.381
		l-2.847-9.16h1.556l2.019,7.357h0.032l1.875-7.357h1.486L153.797,73.042"
                />
                <path
                  fill="#717576"
                  d="M162.498,65.032c0-0.906,0.107-1.749,0.318-2.526c0.213-0.777,0.531-1.454,0.961-2.025
		c0.433-0.571,0.977-1.02,1.637-1.344c0.661-0.324,1.434-0.484,2.318-0.484c0.883,0,1.652,0.16,2.315,0.484
		c0.66,0.324,1.206,0.771,1.642,1.344c0.439,0.571,0.764,1.248,0.974,2.025c0.215,0.777,0.319,1.62,0.319,2.526
		c0,0.907-0.104,1.752-0.319,2.53c-0.21,0.775-0.533,1.453-0.974,2.023c-0.436,0.571-0.98,1.02-1.642,1.344
		c-0.663,0.324-1.435,0.486-2.315,0.486c-0.887,0-1.657-0.162-2.318-0.486c-0.66-0.324-1.204-0.771-1.637-1.344
		c-0.43-0.57-0.748-1.248-0.961-2.023C162.605,66.784,162.498,65.939,162.498,65.032 M165.467,65.032
		c0,0.591,0.037,1.146,0.115,1.672c0.075,0.526,0.203,0.979,0.381,1.371c0.178,0.387,0.409,0.694,0.698,0.919
		c0.286,0.225,0.647,0.335,1.071,0.335c0.422,0,0.777-0.11,1.064-0.335c0.291-0.225,0.525-0.532,0.7-0.919
		c0.177-0.392,0.308-0.845,0.39-1.371s0.125-1.081,0.125-1.672c0-0.589-0.043-1.146-0.125-1.669
		c-0.082-0.526-0.213-0.982-0.39-1.372c-0.175-0.39-0.409-0.695-0.7-0.918c-0.287-0.224-0.643-0.339-1.064-0.339
		c-0.424,0-0.785,0.115-1.071,0.339c-0.289,0.223-0.521,0.528-0.698,0.918s-0.306,0.846-0.381,1.372
		C165.504,63.889,165.467,64.443,165.467,65.032z"
                />
                <rect x="174.855" y="57.943" fill="#717576" width="2.793" height="13.262" />
                <path
                  fill="#717576"
                  d="M185.165,64.309c-0.38-0.151-0.743-0.273-1.099-0.36c-0.354-0.091-0.735-0.136-1.146-0.136
		c-0.369,0-0.634,0.071-0.799,0.213c-0.164,0.143-0.246,0.295-0.246,0.461c0,0.141,0.029,0.263,0.087,0.363
		c0.062,0.1,0.162,0.198,0.31,0.299s0.347,0.201,0.596,0.302c0.246,0.101,0.559,0.221,0.935,0.36
		c0.236,0.096,0.47,0.205,0.699,0.328c0.231,0.125,0.437,0.289,0.619,0.495c0.183,0.205,0.327,0.462,0.44,0.761
		c0.11,0.301,0.168,0.67,0.168,1.104c0,0.521-0.086,0.959-0.265,1.315c-0.179,0.362-0.42,0.652-0.731,0.877
		c-0.312,0.225-0.678,0.39-1.09,0.494c-0.412,0.106-0.849,0.16-1.309,0.16c-0.413,0-0.895-0.031-1.449-0.089
		c-0.553-0.059-1.078-0.177-1.574-0.354l0.069-2.156c0.345,0.202,0.714,0.355,1.116,0.469c0.398,0.111,0.796,0.168,1.184,0.168
		c0.33,0,0.586-0.025,0.771-0.078c0.183-0.056,0.321-0.123,0.417-0.205c0.093-0.08,0.152-0.173,0.178-0.272
		c0.023-0.101,0.032-0.202,0.032-0.31c0-0.201-0.168-0.369-0.513-0.504c-0.344-0.136-0.812-0.328-1.416-0.574
		c-0.246-0.107-0.486-0.235-0.715-0.389c-0.229-0.154-0.433-0.344-0.608-0.566c-0.179-0.225-0.324-0.489-0.437-0.797
		c-0.111-0.306-0.168-0.664-0.168-1.08c0-0.244,0.051-0.527,0.149-0.848c0.103-0.316,0.282-0.617,0.543-0.9
		c0.259-0.282,0.61-0.521,1.058-0.715c0.45-0.196,1.016-0.293,1.696-0.293c0.58,0,1.095,0.039,1.542,0.123
		c0.447,0.082,0.789,0.166,1.023,0.248L185.165,64.309"
                />
                <polyline
                  fill="#717576"
                  points="186.827,61.991 193.21,61.991 193.21,64.273 189.762,69.117 193.3,69.117 193.3,71.205
		186.723,71.205 186.723,68.906 190.238,64.079 186.827,64.079 186.827,61.991 	"
                />
                <path
                  fill="#717576"
                  d="M194.023,61.991h1.59V60.1l2.723-0.867v2.759h1.807v1.963h-1.807v4.228c0,0.448,0.088,0.752,0.268,0.91
		c0.178,0.157,0.398,0.237,0.674,0.237c0.199,0,0.365-0.019,0.5-0.052c0.136-0.036,0.277-0.084,0.42-0.144v1.964
		c-0.143,0.061-0.391,0.115-0.746,0.168c-0.352,0.053-0.751,0.079-1.201,0.079c-0.366,0-0.708-0.046-1.031-0.141
		c-0.326-0.095-0.613-0.248-0.859-0.461c-0.248-0.212-0.445-0.491-0.592-0.841c-0.148-0.346-0.221-0.774-0.221-1.279v-4.67h-1.521
		v-1.962"
                />
                <path
                  fill="#717576"
                  d="M206.49,72.055c-0.344,1.084-0.77,1.867-1.282,2.351c-0.511,0.483-1.159,0.726-1.935,0.726
		c-0.252,0-0.535-0.021-0.85-0.061c-0.318-0.043-0.595-0.098-0.833-0.168l0.091-2.07c0.092,0.059,0.213,0.109,0.36,0.148
		c0.146,0.043,0.321,0.062,0.522,0.062c0.232,0,0.438-0.059,0.608-0.176c0.171-0.118,0.315-0.266,0.433-0.442
		s0.205-0.373,0.258-0.592c0.052-0.219,0.078-0.427,0.078-0.627l-3.2-9.214h2.899l1.717,6.242h0.035l1.625-6.242h2.725
		L206.49,72.055"
                />
                <path
                  fill="#717576"
                  d="M215.9,65.529c0-0.577-0.112-0.965-0.328-1.159c-0.223-0.195-0.471-0.291-0.754-0.291
		c-0.447,0-0.764,0.176-0.944,0.528c-0.185,0.354-0.274,0.832-0.274,1.433v5.165h-2.795V63.92c0-0.449-0.007-0.824-0.02-1.123
		c-0.012-0.3-0.029-0.568-0.051-0.806h2.547c0.023,0.2,0.041,0.432,0.054,0.688c0.009,0.26,0.017,0.545,0.017,0.85h0.035
		c0.213-0.506,0.514-0.912,0.913-1.221c0.394-0.305,0.954-0.459,1.688-0.459c0.505,0,0.931,0.09,1.281,0.266
		c0.349,0.178,0.629,0.416,0.838,0.716c0.213,0.303,0.363,0.651,0.451,1.053c0.09,0.401,0.133,0.832,0.133,1.29v6.031H215.9V65.529"
                />
                <rect x="220.407" y="65.052" fill="#717576" width="4.399" height="2.157" />
                <polyline
                  fill="#717576"
                  points="236.796,61.443 236.764,61.443 233.986,71.205 232.076,71.205 229.406,61.443 229.369,61.443
		229.369,71.205 226.721,71.205 226.721,58.863 231.084,58.863 233.085,66.802 233.121,66.802 235.277,58.863 239.451,58.863
		239.451,71.205 236.796,71.205 236.796,61.443 	"
                />
                <path
                  fill="#717576"
                  d="M242.229,62.436c0.176-0.06,0.383-0.123,0.623-0.188c0.244-0.064,0.492-0.126,0.756-0.186
		c0.256-0.058,0.523-0.107,0.802-0.148c0.274-0.041,0.54-0.062,0.788-0.062c0.812,0,1.475,0.08,1.988,0.238
		c0.51,0.158,0.916,0.391,1.215,0.689c0.291,0.301,0.494,0.66,0.605,1.078c0.113,0.42,0.168,0.884,0.168,1.388v3.36
		c0,0.344,0,0.643,0,0.903c0,0.246,0.004,0.47,0.01,0.671c0.006,0.2,0.016,0.383,0.026,0.549c0.013,0.162,0.022,0.323,0.036,0.478
		h-2.551l-0.069-1.272h-0.035c-0.036,0.141-0.124,0.295-0.265,0.459s-0.324,0.317-0.547,0.459c-0.225,0.144-0.483,0.26-0.779,0.354
		c-0.295,0.095-0.604,0.142-0.936,0.142c-0.861,0-1.549-0.254-2.071-0.76c-0.519-0.509-0.775-1.186-0.775-2.035
		c0-0.507,0.091-0.938,0.274-1.289c0.183-0.354,0.412-0.646,0.697-0.877c0.283-0.229,0.6-0.406,0.943-0.529
		c0.35-0.123,0.693-0.215,1.026-0.273c0.337-0.059,0.649-0.096,0.938-0.105c0.291-0.013,0.516-0.019,0.682-0.019h0.847
		c0-0.437-0.071-0.774-0.213-1.017c-0.14-0.242-0.302-0.418-0.492-0.53s-0.378-0.177-0.563-0.194
		c-0.189-0.02-0.34-0.027-0.444-0.027c-0.354,0-0.729,0.054-1.129,0.158c-0.403,0.106-0.88,0.325-1.433,0.655L242.229,62.436
		 M246.626,66.768c-0.28,0-0.588,0.012-0.919,0.034c-0.326,0.023-0.639,0.089-0.928,0.193c-0.288,0.106-0.528,0.267-0.723,0.478
		c-0.195,0.213-0.293,0.514-0.293,0.902c0,0.307,0.055,0.541,0.159,0.699s0.226,0.275,0.36,0.354
		c0.134,0.078,0.268,0.121,0.398,0.133c0.129,0.013,0.223,0.019,0.282,0.019c0.058,0,0.179-0.015,0.363-0.044
		s0.37-0.115,0.563-0.258c0.195-0.143,0.364-0.364,0.512-0.672c0.146-0.307,0.222-0.74,0.222-1.309v-0.529H246.626z"
                />
                <polyline
                  fill="#717576"
                  points="250.695,61.991 257.079,61.991 257.079,64.273 253.631,69.117 257.17,69.117 257.17,71.205
		250.592,71.205 250.592,68.906 254.107,64.079 250.695,64.079 250.695,61.991 	"
                />
                <path
                  fill="#717576"
                  d="M266.503,69.277c0,0.447,0.005,0.82,0.019,1.121c0.013,0.301,0.029,0.57,0.055,0.807h-2.549
		c-0.022-0.2-0.037-0.432-0.049-0.689c-0.017-0.26-0.021-0.543-0.021-0.849h-0.035c-0.212,0.506-0.516,0.913-0.91,1.218
		c-0.395,0.309-0.959,0.461-1.689,0.461c-0.508,0-0.934-0.085-1.282-0.255c-0.345-0.173-0.624-0.409-0.841-0.707
		c-0.211-0.302-0.359-0.655-0.45-1.063c-0.087-0.403-0.133-0.838-0.133-1.297v-6.031h2.797v5.676c0,0.578,0.106,0.966,0.325,1.159
		c0.219,0.194,0.468,0.291,0.753,0.291c0.446,0,0.763-0.175,0.942-0.529c0.184-0.354,0.275-0.833,0.275-1.433v-5.164h2.794V69.277"
                />
                <path
                  fill="#717576"
                  d="M268.445,63.92c0-0.449-0.006-0.824-0.018-1.123c-0.013-0.3-0.031-0.568-0.052-0.806h2.547
		c0.013,0.271,0.024,0.549,0.036,0.832c0.008,0.282,0.021,0.56,0.033,0.832h0.035c0.142-0.248,0.279-0.482,0.414-0.7
		c0.137-0.217,0.299-0.409,0.48-0.574s0.405-0.294,0.67-0.39c0.266-0.095,0.598-0.142,1-0.142h0.405v2.582
		c-0.151-0.035-0.296-0.062-0.433-0.08c-0.135-0.018-0.307-0.025-0.504-0.025c-0.412,0-0.735,0.078-0.972,0.238
		c-0.237,0.16-0.42,0.373-0.548,0.636c-0.133,0.267-0.216,0.563-0.251,0.893c-0.033,0.332-0.052,0.662-0.052,0.993v4.119h-2.795
		V63.92"
                />
                <path
                  fill="#717576"
                  d="M280.189,72.055c-0.34,1.084-0.77,1.867-1.283,2.351c-0.512,0.483-1.159,0.726-1.934,0.726
		c-0.25,0-0.535-0.021-0.851-0.061c-0.317-0.043-0.595-0.098-0.833-0.168l0.091-2.07c0.093,0.059,0.214,0.109,0.36,0.148
		c0.148,0.043,0.32,0.062,0.521,0.062c0.238,0,0.442-0.059,0.61-0.176c0.172-0.118,0.314-0.266,0.434-0.442
		c0.116-0.177,0.203-0.373,0.258-0.592c0.053-0.219,0.078-0.427,0.078-0.627l-3.201-9.214h2.9l1.717,6.242h0.034l1.625-6.242h2.726
		L280.189,72.055"
                />
              </g>
            </svg>
          </li>
          <li style={{ paddingTop: '15px' }}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="149px"
              height="26px"
              viewBox="-0.676 -0.371 149 26"
              enableBackground="new -0.676 -0.371 149 26"
              xmlSpace="preserve"
            >
              <defs>
              </defs>
              <path
                fill="#DF0024"
                d="M16.921,2.054l-2.509,4.658c-0.584-0.467-1.233-0.837-1.947-1.111c-0.712-0.274-1.458-0.414-2.234-0.421
	c-0.62,0-1.215,0.161-1.786,0.482S7.567,6.467,7.527,7.11C7.542,7.556,7.703,7.921,8.01,8.206c0.308,0.285,0.676,0.515,1.105,0.688
	c0.428,0.174,0.832,0.314,1.211,0.422l1.608,0.46c1.728,0.445,3.146,1.148,4.259,2.11c1.111,0.962,1.687,2.362,1.725,4.202
	c0.016,1.167-0.16,2.295-0.531,3.386c-0.369,1.09-1.029,2.034-1.978,2.834c-0.965,0.761-2.044,1.314-3.237,1.662
	s-2.409,0.519-3.647,0.514c-1.537-0.007-3.03-0.239-4.479-0.697C2.596,23.327,1.248,22.682,0,21.849l2.702-4.842
	c0.788,0.676,1.648,1.239,2.581,1.689c0.934,0.449,1.938,0.684,3.016,0.7c0.775,0.005,1.47-0.181,2.084-0.555
	c0.613-0.375,0.938-0.967,0.973-1.773c-0.021-0.557-0.253-1.007-0.692-1.351c-0.438-0.344-0.953-0.615-1.542-0.813
	C8.531,14.707,8,14.55,7.527,14.433c-1.326-0.335-2.48-0.727-3.46-1.174c-0.979-0.448-1.739-1.085-2.28-1.91
	S0.973,9.376,0.965,7.906c0.014-1.571,0.377-2.936,1.09-4.093c0.712-1.157,1.694-2.055,2.947-2.69
	c1.252-0.637,2.694-0.96,4.327-0.969c1.251,0.007,2.553,0.178,3.904,0.514C14.586,1.004,15.814,1.466,16.921,2.054z"
              />
              <path
                fill="#DF0024"
                d="M41.521,5.358v5.76h0.611c0.702,0.012,1.385-0.052,2.049-0.188c0.665-0.138,1.214-0.413,1.649-0.829
	s0.661-1.037,0.677-1.863c-0.016-0.827-0.241-1.447-0.677-1.863s-0.984-0.691-1.649-0.828c-0.664-0.137-1.347-0.2-2.049-0.188
	H41.521z M47.762,14.397l7.624,9.467h-7.85l-5.951-8.885h-0.064v8.885h-6.306V0.762h9.426c1.613-0.003,3.052,0.245,4.315,0.747
	c1.264,0.501,2.262,1.277,2.993,2.326c0.731,1.05,1.104,2.395,1.12,4.036c-0.006,1.629-0.461,3.042-1.363,4.239
	C50.803,13.308,49.488,14.069,47.762,14.397z M29.121,5.854V23.87h-6.305V5.854h-5.211V0.768h16.727v5.086H29.121z"
              />
              <path
                fill="#DF0024"
                d="M85.702,5.325v4.474h0.708c0.873,0.029,1.651-0.099,2.332-0.384c0.681-0.284,1.04-0.902,1.077-1.854
	c-0.013-0.692-0.204-1.199-0.571-1.52c-0.367-0.319-0.83-0.522-1.387-0.606c-0.558-0.085-1.127-0.122-1.709-0.11H85.702z
	 M89.112,23.832h-9.716V0.729h9.008c2.187-0.026,3.926,0.424,5.22,1.348c1.293,0.925,1.955,2.477,1.986,4.657
	c0.011,1.049-0.188,1.977-0.6,2.785c-0.411,0.808-1.101,1.452-2.071,1.934v0.062c1.728,0.231,2.986,0.863,3.78,1.896
	s1.186,2.361,1.175,3.987c-0.026,1.604-0.457,2.877-1.291,3.82c-0.834,0.942-1.914,1.617-3.241,2.021
	C92.035,23.645,90.618,23.842,89.112,23.832z M85.702,14.272v4.964h0.804c0.649,0.017,1.359-0.007,2.131-0.071
	c0.771-0.063,1.438-0.269,2.002-0.614c0.563-0.347,0.858-0.936,0.886-1.766c-0.025-0.909-0.32-1.54-0.886-1.891
	c-0.563-0.353-1.242-0.549-2.035-0.591c-0.794-0.042-1.546-0.052-2.258-0.031H85.702z M68.875,15.26l-2.574-7.354h-0.064
	l-2.605,7.354H68.875z M70.579,19.856h-8.686l-1.673,4.014h-6.691l9.329-23.103h6.885l9.136,23.103h-6.724L70.579,19.856z"
              />
              <path
                fill="#DF0024"
                d="M112.955,15.26l-2.573-7.354h-0.064l-2.605,7.354H112.955z M114.66,19.856h-8.686l-1.673,4.014H97.61
	l9.329-23.103h6.885l9.136,23.103h-6.724L114.66,19.856z"
              />
              <path
                fill="#DF0024"
                d="M135.188,10.97h12.514c0.004,1.675-0.147,3.258-0.454,4.749s-0.98,2.952-2.022,4.382
	c-1.205,1.569-2.664,2.72-4.375,3.451c-1.712,0.73-3.589,1.092-5.63,1.083c-2.56-0.016-4.807-0.531-6.741-1.547
	c-1.935-1.017-3.445-2.436-4.532-4.259c-1.087-1.822-1.64-3.952-1.659-6.389c0.019-2.517,0.576-4.704,1.674-6.562
	c1.1-1.857,2.632-3.298,4.599-4.321s4.262-1.542,6.886-1.558c2.506,0,4.69,0.537,6.554,1.612c1.863,1.076,3.324,2.694,4.384,4.854
	l-5.984,2.39c-0.439-1.118-1.104-2.007-1.994-2.666c-0.89-0.658-1.973-0.995-3.249-1.011c-1.384,0.024-2.544,0.395-3.479,1.107
	c-0.936,0.714-1.641,1.623-2.114,2.729c-0.474,1.104-0.711,2.257-0.713,3.457c0.003,1.217,0.249,2.373,0.738,3.467
	c0.489,1.095,1.209,1.99,2.16,2.686c0.95,0.695,2.118,1.056,3.504,1.078c1.393,0,2.549-0.336,3.471-1.007
	c0.921-0.671,1.459-1.674,1.612-3.007h-5.147V10.97z"
              />
            </svg>

          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="75" viewBox="0 0 200 75">
              <defs>
                <clipPath id="clip-Web_1920_6">
                  <rect width="200" height="75" />
                </clipPath>
              </defs>
              <g id="mggp" data-name="Web 1920 – 1" clipPath="url(#clip-Web_1920_6)">
                <rect width="200" height="75" fill="#fff" />
                <g id="mggp2" transform="translate(0 15)">
                  <g id="Group_1" data-name="Group 1">
                    <rect id="mggp2_Rectangle_1" data-name="Rectangle 1" width="13.175" height="13.175" transform="translate(138.727 15.101)" fill="#007270" />
                    <rect id="Rectangle_2" data-name="Rectangle 2" width="13.175" height="13.175" transform="translate(138.727 31.57)" fill="#007270" />
                    <rect id="Rectangle_3" data-name="Rectangle 3" width="13.175" height="13.175" transform="translate(155.195 15.101)" fill="#007270" />
                    <rect id="Rectangle_4" data-name="Rectangle 4" width="13.175" height="13.175" transform="translate(155.195 31.57)" fill="#007270" />
                  </g>
                  <g id="Group_2" data-name="Group 2">
                    <path id="Path_1" data-name="Path 1" d="M17.874,38.626a3.087,3.087,0,0,0-2.028-.782A2.69,2.69,0,0,0,13.1,40.726a2.5,2.5,0,0,0,2.746,2.614,2.681,2.681,0,0,0,1.8-.626V41.329h-2.04V40.011h3.418v3.3a4.006,4.006,0,0,1-3.179,1.408c-3,0-4.216-1.932-4.216-3.987a4.013,4.013,0,0,1,4.216-4.234,4.255,4.255,0,0,1,2.962,1.195Z" fill="#007270" />
                    <path id="Path_2" data-name="Path 2" d="M27.824,44.695H26.035l-2.324-2.666H22.264v2.666h-1.5V36.708c1.265,0,2.529.011,3.794.011a2.644,2.644,0,0,1,2.871,2.644,2.366,2.366,0,0,1-2.017,2.507l2.415,2.723v.1Zm-5.56-6.574v2.552h2.29a1.279,1.279,0,1,0,0-2.552h-2.29Z" fill="#007270" />
                    <path id="Path_3" data-name="Path 3" d="M35.824,36.731V41.2a3.2,3.2,0,0,1-3.453,3.491A3.294,3.294,0,0,1,28.828,41.2V36.731h1.493V41.2a1.934,1.934,0,0,0,2.062,2.153A1.884,1.884,0,0,0,34.331,41.2V36.731Z" fill="#007270" />
                    <path id="Path_4" data-name="Path 4" d="M41.5,42.348H39.246v2.347h-1.5V36.72c1.254,0,2.507-.011,3.76-.011A2.821,2.821,0,1,1,41.5,42.348ZM39.246,40.97H41.5a1.413,1.413,0,1,0,0-2.826H39.246Z" fill="#007270" />
                    <path id="Path_5" data-name="Path 5" d="M50.794,43.191h-4.17l-.683,1.5H44.312l3.578-7.976h1.64l3.578,7.976H51.467Zm-2.085-4.8-1.481,3.4H50.19Z" fill="#007270" />
                    <path id="Path_6" data-name="Path 6" d="M63.717,38.919l-2.6,3.463h-.3l-2.541-3.474v5.8h-1.5V36.732H58.51L61,40.161,63.5,36.732h1.72v7.975h-1.5V38.919Z" fill="#007270" />
                    <path id="Path_7" data-name="Path 7" d="M73.023,38.629A3.09,3.09,0,0,0,71,37.846a2.693,2.693,0,0,0-2.746,2.886A2.5,2.5,0,0,0,71,43.35a2.682,2.682,0,0,0,1.8-.626V41.336H70.756v-1.32h3.418v3.3A4,4,0,0,1,71,44.726c-3,0-4.216-1.935-4.216-3.994A4.016,4.016,0,0,1,71,36.492a4.253,4.253,0,0,1,2.962,1.2Z" fill="#007270" />
                    <path id="Path_8" data-name="Path 8" d="M81.729,38.626a3.089,3.089,0,0,0-2.028-.782,2.691,2.691,0,0,0-2.746,2.883A2.5,2.5,0,0,0,79.7,43.342a2.685,2.685,0,0,0,1.8-.625V41.331h-2.04V40.012h3.418v3.3A4,4,0,0,1,79.7,44.717c-3,0-4.216-1.934-4.216-3.99A4.014,4.014,0,0,1,79.7,36.492a4.252,4.252,0,0,1,2.962,1.2Z" fill="#007270" />
                    <path id="Path_9" data-name="Path 9" d="M88.37,42.348H86.114v2.347h-1.5V36.72c1.253,0,2.507-.011,3.76-.011A2.821,2.821,0,1,1,88.37,42.348ZM86.113,40.97h2.256a1.413,1.413,0,1,0,0-2.826H86.113Z" fill="#007270" />
                  </g>
                  <g id="Group_3" data-name="Group 3">
                    <path id="Path_10" data-name="Path 10" d="M36.927,8.986,27.58,21.448H26.515l-9.14-12.5V29.808h-5.41V1.116H18.2l8.976,12.339L36.148,1.116h6.189V29.808h-5.41Z" fill="#575756" />
                    <path id="Path_11" data-name="Path 11" d="M68.754,8.085a11,11,0,0,0-7.3-2.867c-6.148,0-9.879,4.673-9.879,10.573,0,4.712,2.747,9.591,9.879,9.591a9.53,9.53,0,0,0,6.476-2.3V18H60.6V13.168h12.3V25.26a14.3,14.3,0,0,1-11.436,5.166c-10.78,0-15.166-7.092-15.166-14.635C46.291,7.718,51.333.255,61.457.255A15.154,15.154,0,0,1,72.113,4.644Z" fill="#575756" />
                    <path id="Path_12" data-name="Path 12" d="M99.472,8.085a11,11,0,0,0-7.295-2.867c-6.148,0-9.879,4.673-9.879,10.573,0,4.712,2.747,9.591,9.879,9.591a9.53,9.53,0,0,0,6.476-2.3V18H91.315V13.168h12.3V25.26a14.3,14.3,0,0,1-11.436,5.166c-10.781,0-15.166-7.092-15.166-14.635C77.01,7.718,82.051.255,92.176.255a15.154,15.154,0,0,1,10.656,4.389Z" fill="#575756" />
                    <path id="Path_13" data-name="Path 13" d="M121.582,21.325h-8.115v8.444h-5.41V1.076c4.508,0,9.016-.04,13.525-.04C135.6,1.036,135.641,21.325,121.582,21.325Zm-8.116-4.96h8.115c6.927,0,6.887-10.166,0-10.166h-8.115Z" fill="#575756" />
                  </g>
                </g>
              </g>
            </svg>

          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="135" height="50" viewBox="0 0 135 50">
              <defs>
                <clipPath id="clip-Web_1920_7">
                  <rect width="135" height="50" />
                </clipPath>
              </defs>
              <g id="sweco" data-name="Web 1920 – 1" clipPath="url(#clip-Web_1920_7)">
                <rect width="135" height="50" fill="#fff" />
                <path id="Path_1" data-name="Path 1" d="M319.651,232.765,328.9,223.2l9.307,9.566v3.2H319.671v-3.2Zm-2.4-21.749,7.369,7.709H313.4v3.515h11.164l-8.648,8.907v8.568H341.7v-8.568l-8.647-8.907h11.224v-3.515H333.052l7.25-7.569-2.6-2.656-8.967,9.307-8.907-9.506-2.576,2.716Z" transform="translate(-218.488 -197.701)" />
                <path id="Path_2" data-name="Path 2" d="M181.038,188.095a3.727,3.727,0,0,1-3.655,3.795,3.766,3.766,0,0,1-3.655-3.795,3.657,3.657,0,1,1,7.309,0m-33.891,29.577c2.4,0,3.255-2.317,3.255-4.853,0-2.716-.8-4.853-3.255-4.853-2.4,0-3.4,2.2-3.4,4.853.02,2.456,1.019,4.853,3.4,4.853m0-12.821c4.853,0,7.449,2.856,7.449,7.909,0,4.853-2.6,7.909-7.449,7.909s-7.449-3.056-7.449-7.909c.08-5.053,2.6-7.909,7.449-7.909m-14.479,0a19.6,19.6,0,0,1,4.314.659l-.34,3.4a8.4,8.4,0,0,0-3.854-1,4.564,4.564,0,0,0-4.653,4.853,4.638,4.638,0,0,0,4.993,4.853,9.117,9.117,0,0,0,3.715-.859l.14,3.2a13.879,13.879,0,0,1-4.314.659c-4.054,0-8.568-2-8.568-7.909,0-5.332,3.715-7.849,8.568-7.849" transform="translate(-66.994 -178.494)" />
                <path id="Path_3" data-name="Path 3" d="M-39.434,288.2v15.478h10.5v-3.056h-6.79v-3.315h6.111v-3.056H-35.72v-3.2h6.65V288.2Zm-6.91,0-1.937,11.763L-50.4,288.2h-4.993l-1.937,11.763h-.12l-1.8-11.763H-63.3l3.255,15.478h5.113l2-11.963,2.057,11.963h4.993l3.4-15.478Z" transform="translate(82.98 -261.644)" />
                <path id="Path_4" data-name="Path 4" d="M-125.37,287.2a11.533,11.533,0,0,1,3.854.539l-.34,3.2a6.621,6.621,0,0,0-3.2-.659c-1,0-2.716,0-2.716,1.338,0,2.516,7.11.859,7.11,6.371,0,4.054-3.255,5.053-6.65,5.053a14.063,14.063,0,0,1-4.314-.659l.34-3.4a9.053,9.053,0,0,0,4.054,1c1.258,0,2.4-.34,2.4-1.538-.06-2.8-6.97-.939-6.97-6.511-.02-3.675,3.515-4.733,6.431-4.733" transform="translate(137.8 -260.843)" />
              </g>
            </svg>
          </li>
          <li>
            <GatsbyImage
              image={getImage(data.b_act)}
              alt="B ACT"
              width="160"
              height="50"
              loading="eager"
            />
          </li>
        </Clients>
      </div>
    </section>
  </Main>
);

export const query = graphql`
  query {
    hero: file(relativePath: {regex: "/home\/home.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 1920
        )
      }
    },
    mapa_ewidencji: file(relativePath: {regex: "/home\/mapa-ewidencji.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 530
        )
      }
    },
    airport: file(relativePath: {regex: "/home\/airport.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 530
        )
      }
    },
    nadzor: file(relativePath: {regex: "/home\/nadzor.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 530
        )
      }
    },
    obsluga: file(relativePath: {regex: "/home\/obsluga.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 530
        )
      }
    },
    b_act: file(relativePath: {regex: "/clients\/b-act.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 160
        )
      }
    }
  }
`;

export default IndexPage;
